.candidate-list-no-candidates-found {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
  }
  
  .candidate-list-no-candidates-found-message {
    height: 24px;
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }