@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");

:root {
  --app-width: 90%;
  --app-max-width: 1366px;
  --navbar-height: 64px;
  --body-content-padding: 50px;
  --arya-accent-color: #13c26b;
  --arya-error-color: #f5222d;

  --color-sourced: #cce5ff;
  --color-shortlisted: #e8e4fa;
  --color-connected: #d0f3e1;
  --color-sourced-activated: #007bff;
  --color-shortlisted-activated: #8e76e4;
  --color-connected-activated: #13c26b;
}

*,
*::after,
*::before {
  outline: none;
  box-sizing: border-box;
}

html,
body {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  display: flow-root;
}

#root {
  width: 100%;
  background-color: #ffffff;
}

#body-content {
  width: 100%;
  margin: auto;
  margin-top: var(--navbar-height);
  min-height: 100%;

  > * {
    width: var(--app-width);
    max-width: var(--app-max-width);
    margin: auto;
  }

  > .body-content-error {
    width: 100%;
    max-width: 100%;
  }
}

#messages-footer {
  // width: 100%;
  position: fixed;
  bottom: 0;
  right: 4%;
  z-index: 2000;

  #message-popup-container {
    width: 100%;
    margin: auto;
  }
}

.popup-message-dropdown {
  z-index: 2000;
}

a,
a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
}

body {
  ::-webkit-scrollbar {
    width: 6px;
  }

  // ::-webkit-scrollbar-track {
  //   padding-right: 2px;
  //   border-radius: 10px;
  // }

  *::-webkit-scrollbar-thumb {
    background-color: #dfdfdf;
    border-radius: 20px;
  }
}

.arya-logo {
  text-align: center;
  margin-bottom: 40px;
  margin-left: -50px;
}

.fixed-popover-menu {
  position: fixed;
  z-index: 2010;

  .ant-popover-content > .ant-popover-arrow {
    margin-right: 16px;
  }
}

.ant-pagination {
  padding-top: 10px;
  float: right;
}

.ant-message {
  z-index: 3000;
}

#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
#navbar-wrapper {
  background-color: #1f2730;
  height: var(--navbar-height);
  width: 100%;
  position: fixed;
  z-index: 2000;
  top: 0;
}

.navbar {
  width: var(--app-width);
  max-width: var(--app-max-width);
  margin: auto;
  height: 100%;
  color: #a8b7c7;
  display: flex;
  align-items: center;
  padding: 0px;
  position: relative;
}

.navbar .anticon {
  padding: 12px;
}

.navbar a {
  color: #a8b7c7;
  display: block;
  height: 100%;
}

.navbar-jobs-tab {
  margin-left: auto;
}

.navbar-menu-tab-item {
  font-size: 16px;
  padding: 15px;
  height: 100%;
  display: flex;
  align-items: center;

  &.last {
    padding-right: 24px;
  }
}

.navbar-menu-tab-item.active {
  background-color: #353d45;
  border-bottom: 4px solid #13c26b;
  color: #ffffff;
}

.navbar-menu-item-icon {
  padding: 15px 24px 15px 6px;
  cursor: pointer;

  &.last {
    padding: 15px 0px 15px 6px;
  }

  .arrow-icon {
    padding: 12px 0px;
    margin-left: 6px;
  }
}

.logo img {
  height: 35px;
  cursor: pointer;
}

.navbar-notification-icon {
  font-size: 20px;
}

.avatar-icon {
  margin-right: 6px;
}

.system-notifications,
.connect-notifications {
  max-height: 300px;
  overflow-y: scroll;
}

.go-to-connect {
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar .connect-notification-icon {
  padding: 0px;
}

.navbar .arya-notification-icon {
  padding: 0px;
}

.navbar .badged-icon {
  // padding: 18px;
  // margin-top: 5px;
  font-size: 22px;

  .connect {
    .ant-badge-count {
      background: #9254de;
      box-shadow: none;
      height: 14px;
      width: 14px;
      line-height: 14px;
      padding: 0 3px;
      font-size: 10px;
      border-radius: 4px;
    }
  }

  .arya {
    .ant-badge-count {
      background-color: #1890ff;
      box-shadow: none;
      height: 14px;
      width: 14px;
      line-height: 14px;
      padding: 0 3px;
      font-size: 10px;
      border-radius: 4px;
    }
  }
}

.connect-notification-icon.wiggle,
.arya-notification-icon.wiggle {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.system-notification-empty,
.connect-notification-empty {
  height: 197px;
  width: 361px;
  border-radius: 6px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.system-notification-empty-icon-wrapper,
.connect-notification-empty-icon-wrapper {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.system-notification-empty-title,
.connect-notification-empty-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: center;
}

.system-notification-empty-description,
.connect-notification-empty-description {
  color: rgba(7, 16, 26, 0.5);
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
}

.supportlogo {
  font-weight: bold;
  line-height: 15px;
  color: #ffffff;
  border-left: 1px solid #fff;
  padding-left: 10px;
  margin-left: 10px;
}

.navbar-popover {
  position: relative;
  z-index: 10000;
  width: 150px;
  .ant-popover-content > .ant-popover-arrow,
  .ant-popover-inner {
    margin-top: 5px;
    margin-right: 16px;
  }
}
