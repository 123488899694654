 
  #title {
    color: #374251;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.44px;
    line-height: 28px;
    max-width: 300px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  #status {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    margin-left: 20px;
  }
  
  #status > .job-status {
    font-size: 14px;
    color: #374251;
    box-shadow: 0 0 0 0px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  
  .statusfix {
    width: 250px;
  }
  
  #status > .job-status > .ant-select-selection,
  #status > .job-status > .ant-select-selection:hover,
  #status > .job-status > .ant-select-selection:active,
  #status > .job-status > .ant-select-selection:focus {
    border: 0px;
    box-shadow: 0 0 0 0px;
  }
  
  .portal-activation-wrapper {
    margin-left: 15%;
    width: 85px;
  }
  
  .portal-activation {
    border: 1px solid #dadbdd;
    padding: 10px 16px 6px 16px;
    border-radius: 4px 0px 0px 4px;
  
    &:hover {
      border: 1px solid #dadbdd;
      box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
      cursor: pointer;
    }
  }
  
  .quick-search-wrapper {
    padding: 10px;
    border: 1px solid #dadbdd;
    border-left: 0px;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
  
    &:hover {
      box-shadow: 0 2px 6px 0 rgba(7, 16, 26, 0.1);
    }
  }
  
  .quick-search {
    height: 100%;
    width: 50px;
    background-color: #efefef;
    border: 0px;
    margin-left: 5px;
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
  
  .quick-search-icon {
    font-size: 20px;
    color: #b4b7ba;
  }
  
  .quick-search.ant-btn {
    padding: 0px;
  }
  
  .job-share-button-wrapper {
    margin-left: auto;
  }
  
  .job-edit-button {
    border-radius: 20px;
    align-self: center;
  }
  
  .more-wrapper {
    margin-left: 10px;
  }
  
  .more {
    width: 32px;
    padding-left: 7px;
    border-radius: 20px;
    align-self: center;
  }
  
  #client {
    color: #374251;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.46px;
    line-height: 22px;
  }
  
  #id {
    color: #374251;
    font-family: Roboto;
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
  }
  
  .bot-config {
    cursor: pointer;
  }
  .org-deatils-div{
    background-color: #FFFFFF;	box-shadow: 0 1px 5px 0 rgba(7,16,26,0.15); display: flex; margin: 20px 0px 40px 0px; padding: 20px; width: 100%;}
  
  .licensecount{
    width: 22%;   margin-right:20px; padding-left: 40px;
  }
  .licensecounthead{color: rgba(7,16,26,0.9); 	font-size: 24px;	font-weight: 500;}
  .licensecounthead span{color: rgba(7,16,26,0.9); 	font-size: 16px;	font-weight: 500;} 
  .licensecountitle{color: rgba(7,16,26,0.5);	 	font-size: 12px;	font-weight: 500;}
  .orgeneralinfo{ border-bottom: 1px solid #E6E7E8; width: 100%; padding-bottom: 40px; margin-bottom: 40PX; display: inline-block; 	 }
  .orginfohead{
    color: rgba(7,16,26,0.9);	 	font-size: 20px;	font-weight: 500;	line-height: 24px;
  }
   
  .orginfosub{	color: rgba(7,16,26,0.7);	font-family: Roboto;	font-size: 16px;}
  .orgmetatitle{	color: rgba(7,16,26,0.9); margin-top: 15px;	 	font-size: 14px;	font-weight: 500;}

  .profile-settings {
    display: flex;
    flex-direction: row;
  }
  
  .navigation-sidebar {
    cursor: pointer;
  }
  
  .orginfo-wrapper {
    flex-grow: 1;
    padding: 0px  100px 0px 25px;
   
  
    .profile-email-signature {
      width: 60%;
      .ql-editor {
        height: 252px;
      }
    }
  }
  
  .navigation-item-title-save {
    display: flex;
    width: 100%;
  }
  
  .navigation-item-title {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
  
  .navigation-item-subtitle {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
  }
  
  .form-item-label {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  
  .navigation-item-form-wrapper {
    padding: 20px 0px;
  }
  
  .profile-settings-input {
    width: 50%;
    margin-bottom: 10px;
  }
  
  .profile-settings-section {
    margin-bottom: 20px;
  }
  