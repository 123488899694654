.client-list {
  padding-top: 40px;
}

.client-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  .clients-count {
    margin-left: 24px;
    margin-right: 0px;
  }

  .active-clients-count {
    margin-left: 16px;
    color: var(--arya-accent-color);
    font-size: 12px;
  }
}

.client-list-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.client-list-column-headers {
  display: flex;
  padding: 12px 20px;
  background-color: #f4f6f8;

  .client-column-header,
  .contact-column-header,
  .primary-contact-column-header,
  .source-count-column-header,
  .status-column-header,
  .updated-column-header {
    color: rgba(6, 16, 27, 0.5);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
  }

  .client-column-header {
    flex: 1.5 1.5 0;
  }

  .contact-column-header {
    flex: 1.5 1.5 0;
  }

  .updated-column-header {
    flex: 1 1 0;
    text-align: center;
  }

  .source-count-column-header {
    flex: 1 1 0;
    text-align: center;
  }

  .status-column-header {
    flex: 1 1 0;
    text-align: center;
  }
}

.client-list-row {
  display: flex;
  padding: 20px 20px;
  border-bottom: 1px solid #e6eaee;

  .client-column-item {
    flex: 1.5 1.5 0;
    cursor: pointer;
  }

  .contact-column-item {
    flex: 1.5 1.5 0;
  }

  .updated-column-item {
    flex: 1 1 0;
    text-align: center;
  }

  .source-count-column-item {
    flex: 1 1 0;
    text-align: center;
  }

  .status-column-item {
    flex: 1 1 0;
    text-align: center;
  }

  .client-column-item {
    .client-name {
      color: rgba(6, 15, 27, 0.9);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .client-id {
      color: rgba(5, 15, 29, 0.7);
      font-size: 12px;
      line-height: 16px;
    }
  }

  .contact-column-item {
    .client-email {
      color: rgba(6, 16, 27, 0.7);
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 7px;
    }

    .contact-number {
      color: rgba(6, 16, 27, 0.7);
      font-size: 12px;
      line-height: 16px;
    }
  }

  .primary-contact-column-item {
    .client-email {
      color: rgba(6, 15, 28, 0.7);
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 7px;
    }

    .contact-number {
      color: rgba(6, 16, 27, 0.7);
      font-size: 12px;
      line-height: 16px;
    }
  }

  .updated-column-item {
    .updated-time {
      color: rgba(4, 14, 30, 0.7);
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  .status-column-item {
    .status-text {
      margin-right: 10px;
    }
  }
}

.client-form-drawer {
  .ant-drawer-content-wrapper {
    width: 650px !important;
  }
}
.user-client-list-controls {
  display: flex;
  align-content: space-around;
  line-height: 40px;
}

.user-client-search {
  height: 40px;
  width: 240px;
  background-color: #ffffff;
  margin-right: 16px;
}

.user-client-search > .ant-input {
  border-radius: 20px;
}

.filter-users-clients {
  img {
    height: 18px;
  }

  line-height: 40px;
  border: none;
}

.add-user-icon {
  font-size: 40px;
  color: var(--arya-accent-color);
  cursor: pointer;
}
.ant-drawer {
  z-index: 2001;
}
.ant-form-item-label {
  line-height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 500;
}

.ats-id-input,
.source-count-input {
  width: 170px;
}

.emails,
.contact-numbers,
.clientid {
  display: flex;
}

.company-email,
.source-count-item {
  margin-right: 10px;
}

.primary-email,
.company-email,
.phone-number-input,
.mobile-number-input,
.company-address,
.source-count-item {
  width: 260px;
}

.company-url {
  width: 350px;
}
 
.drawer-select-dropdown {
  z-index: 3000;
}
.country-select {
  width: 80px;
  height: 32px;
}
