.dashboard-tabs {
  width: 80vw;
  margin: 100px auto;
}

.kibana-aryacore-iframe {
  margin: 50px auto 100px auto;
  .heading {
    font-weight: bolder;
    font-size: large;
    margin-top: 3px;
  }
}

.date-filter-wrapper {
  display: flex;
  justify-content: space-between;
}

.date-filter {
  width: 425px;
  display: flex;
  justify-content: space-between;
  float: right;
}

.date-filter-button {
  margin-top: 3px;
}
