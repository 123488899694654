.job-list-header {
  display: flex;
  align-items: center;
}

.job-status {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

 

.add-job-button {
  border: none;
  padding: 0px;
  box-shadow: none;
}
