.portalSubTabTitle{
    color: rgba(7,16,26,0.9);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}

.portalSubTabSubTitle{
    color: rgba(7,16,26,0.7);
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}

.portalSubTabHeader{
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 8px;
}