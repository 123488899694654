.auth-container {
  margin-top: 20px;
  margin: 5% auto 50px;
}

.auth-form {
  width: 360px;
  margin: auto;
  padding: 24px 48px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(7, 16, 26, 0.15);
  font-family: Roboto;

  .auth-title,
  .auth-sub-title {
    text-align: center;
  }

  .auth-title {
    color: rgba(7, 16, 26, 0.9);
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0.25em;
  }

  .auth-sub-title {
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2em;
  }

  .user-message {
    font-size: 12px;
    color: var(--arya-accent-color);
  }

  .error-message {
    color: var(--arya-error-color);
    font-size: 12px;
  }

  .auth-label {
    color: rgba(7, 16, 26, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .auth-input {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
  }

  .auth-primary-button,
  .auth-secondary-button {
    width: 100%;
  }

  .auth-primary-button {
    margin-top: 24px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
  }

  .auth-secondary-button {
    margin-top: 16px;
    color: #13c26b;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    border-width: 0;
    box-shadow: unset;
  }
}
