#job-table {
  font-weight: 400;
  margin-top: 28px;
  .ant-empty {
    height: calc(100vh - 280px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-empty-image {
      height: 60px;
    }
  }

  #table-heading {
    padding: 10px 20px;
    background-color: #f4f6f8;
    color: #647182;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .floating-menu-popup {
    z-index: 9;
  }

  .job-list-empty {
    width: 275px;
  }
}

.job-list-no-job-found {
  height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
}

.job-list-no-job-found-message {
  height: 24px;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}

.job-list-no-job-remove-search {
  height: 24px;
  color: #13c26b;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-top: 10px;
}
