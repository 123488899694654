.job-title-wrapper {
  display: flex;
  align-items: baseline;
}

.job-title {
  color: #374251;
  font-size: 16px;
  font-weight: 500;
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px; padding-right: 5px;
}

.job-id {
  color: #647182;
  font-size: 12px;
  line-height: 16px;
  display: inline;
}

.job-time {
  color: #647182;
  font-size: 12px;
  line-height: 16px;
}

.job-id-time {
  display: flex;
  align-items: center;
}
 
.job-card {
  padding: 18px;
  display: flex;
  align-items: center;

  & ~ .job-card {
    border-top: 1px solid #e6eaee;
  }

  &:last-of-type {
    border-bottom: 1px solid #e6eaee;
  }
}
.org-licences {
  color: #1890FF;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
    text-align: right;
}
.org-ats, .org-portals {
  color: rgba(7,16,26,0.9); 	font-size: 14px;	font-weight: 500;	line-height: 16px;
  text-align: right;
}

.org-portals {
  cursor: pointer;
  .org-portals-popover {
    display: inline;
  }
}

.portal-icon-badge{
  margin-left: 5px;
  margin-right: 5px;
}

.org-credit {
  color: rgba(7,16,26,0.9); 	font-size: 14px;	font-weight: 500;	line-height: 16px;
  text-align: center;
}

.org-active {
  color: #83878C;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
.org-textcenter{
  text-align: right;
}

.org-status-change {
  font-size: 14px;
  font-weight: 500;
}

.job-status-change-filter,
  .job-status-change-filter:hover {
    background-color: #f4f6f8;
    border-radius: 30px;
  }
  
  .job-status-change-filter > .ant-select-selection,
  .job-status-change-filter > .ant-select-selection:hover,
  .job-status-change-filter > .ant-select-selection:active,
  .job-status-change-filter > .ant-select-selection:focus {
    border: 0px;
    box-shadow: 0 0 0 0px;
    padding: 14px 8px;
    display: flex;
    align-items: center;
    max-width: 85px;
    min-width: 70px;
    .ant-select-selection-selected-value {
      max-width: 120px;
      overflow: hidden;
    }
  }
  
  .job-status-change-filter > .ant-select-selection:hover {
    background-color: #f4f6f8;
    border-radius: 30px;
  }
  .pilotact{ border: 1px solid #FFADD2; padding: 4px 10px;  vertical-align: middle;	border-radius: 11px;	background-color: #FFF0F6;font-size: 10px;	font-weight: bold;	 color: #EB2F96 }
  .paidact{ border: 1px solid #B7EB8F; padding: 4px 10px;  vertical-align: middle;	border-radius: 11px;	background-color: #F6FFED;font-size: 10px;	font-weight: bold;	 color: #52C41A }
  .demoact{ border: 1px solid #91D5FF;padding: 4px 10px;  vertical-align: middle;	border-radius: 11px;  	background-color: #E6F7FF;font-size: 10px;	font-weight: bold;	 color: #1890FF }
 
 .actbtn-padding{ padding-left: 13px;}