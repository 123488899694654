.form-div-class{
    width: 80%;
    margin: 110px auto;
}
.ant-row>.ant-form-item-control-wrapper, .ant-row>.ant-form-item-control-wrapper>.ant-form-item-control, .ant-row>.ant-form-item-control-wrapper>.ant-form-item-control>.ant-form-item-children{
    width: 100%;
}
 
.job-list-header {
    display: flex;
    align-items: center;
  }


  .job-status {
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
  }
  
  .job-status-filter {
    min-width: 200px;
    font-size: 28px;
    color: rgba(7, 16, 26, 0.9);
    box-shadow: 0 0 0 0px;
  }
  
  .job-status-filter > .ant-select-selection,
  .job-status-filter > .ant-select-selection:hover,
  .job-status-filter > .ant-select-selection:active,
  .job-status-filter > .ant-select-selection:focus {
    border: 0px;
    box-shadow: 0 0 0 0px;
  }
  
  .job-search {
    margin-left: auto;
    margin-right: 15px;
    input {
      border-radius: 30px;
      width: 200px;
      height: 38px;
    }
  
    input:focus {
      width: 400px;
      border: 1px solid var(--arya-accent-color);
    }
  
    .ant-input-suffix {
      z-index: 0;
    }
  }
  
  .job-list-header {
    display: flex;
    align-items: center;
  }
  
  #job-table {
    font-weight: 400;
    margin-top: 28px;
    .ant-empty {
      height: calc(100vh - 280px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ant-empty-image {
        height: 60px;
      }
    }
  
    #table-heading {
      padding: 10px 20px;
      background-color: #f4f6f8;
      color: #647182;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  
    .floating-menu-popup {
      z-index: 9;
    }
  
    .job-list-empty {
      width: 275px;
    }
  }
  
  .job-list-no-job-found {
    height: 24px;
    color: rgba(7, 16, 26, 0.9);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
  }
  
  .job-list-no-job-found-message {
    height: 24px;
    color: rgba(7, 16, 26, 0.7);
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }
  
  .job-list-no-job-remove-search {
    height: 24px;
    color: #13c26b;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    margin-top: 10px;
  }
  .add-job-icon {
    font-size: 40px;
    color: #01cd75;
    margin-left: 0px;;
  }
  .add-job-button {
    border: none;
    padding: 0px; box-shadow:none;
  }
  .job-card {
    padding: 18px;
    display: flex;
    align-items: center;
  
    & ~ .job-card {
      border-top: 1px solid #e6eaee;
    }
  
    &:last-of-type {
      border-bottom: 1px solid #e6eaee;
    }
  }
  
  .job-card:hover {
    box-shadow: 0 2px 8px -2px rgba(32, 38, 50, 0.3);
  }
  
  .job-card ~ .job-card:hover {
    border-top: 1px solid transparent;
  }
  
  .job-title {
    color: #374251;
    font-size: 16px;
    font-weight: 500;
    max-width: 175px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 25px;
  }
  
  .job-id {
    color: #647182;
    font-size: 12px;
    line-height: 16px;
    display: inline;
  }
  
  .job-time {
    color: #647182;
    font-size: 12px;
    line-height: 16px;
  }
  
  .client-name {
    color: #374251;
    font-size: 16px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: 25px;
  }
  
  .client-location {
    color: #647182;
    font-size: 12px;
  }
  
  // .candidate-col {
  //   top: 9px;
  // }
  
  .candidate-button,
  .candidate-button:hover,
  .candidate-button:focus,
  .candidate-button:active {
    height: 17px;
    width: 52px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #fff;
    border: none;
  }
  
  .status-col .ant-btn {
    color: #a8b7c7;
    background: transparent;
    border: none;
  }
  
  .job-status-change-filter {
    // width: 95px;
    font-size: 14px;
    color: #647182;
    background: transparent;
    border: none;
    // margin-top: 8px;
    font-weight: 500;
  }
  
  .job-id-time {
    display: flex;
    align-items: center;
  }
  
  .job-id-time-dot {
    height: 4px;
    width: 4px;
    margin: 0px 8px;
    border-radius: 50%;
    background-color: #838786;
  }
  
  .job-status-change-filter,
  .job-status-change-filter:hover {
    background-color: #f4f6f8;
    border-radius: 30px;
  }
  
  .job-status-change-filter > .ant-select-selection,
  .job-status-change-filter > .ant-select-selection:hover,
  .job-status-change-filter > .ant-select-selection:active,
  .job-status-change-filter > .ant-select-selection:focus {
    border: 0px;
    box-shadow: 0 0 0 0px;
    padding: 14px 8px;
    display: flex;
    align-items: center;
    max-width: 155px;
    .ant-select-selection-selected-value {
      max-width: 140px;
      overflow: hidden;
    }
  }
  
  .job-status-change-filter > .ant-select-selection:hover {
    background-color: #f4f6f8;
    border-radius: 30px;
  }
  
  .source-button {
    width: 55px;
    height: 26px;
    margin: inherit;
    cursor: pointer;
  }
  
  .job-title-wrapper {
    display: flex;
    align-items: baseline;
  }
  
  .job-external-source {
    box-sizing: border-box;
    height: 18px;
    width: 14px;
    border: 1px solid #d3adf7;
    border-radius: 2px;
    background-color: #f9f0ff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  
  .job-external-source-text {
    color: #722ed1;
    font-size: 12px;
    line-height: 20px;
  }
  