.job-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  // height: 52px;
}

.jobtitle-status-wrapper {
  display: flex;
  align-items: center;
}
 
#title {
  color: #374251;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 28px;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#status {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  margin-left: 20px;
}

#status > .job-status {
  font-size: 14px;
  color: #374251;
  box-shadow: 0 0 0 0px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
}
.statusfix {
  width: 250px;
}

#status > .job-status > .ant-select-selection,
#status > .job-status > .ant-select-selection:hover,
#status > .job-status > .ant-select-selection:active,
#status > .job-status > .ant-select-selection:focus {
  border: 0px;
  box-shadow: 0 0 0 0px;
}

#client {
  color: #374251;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.46px;
  line-height: 22px;
}

#id {
  color: #374251;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
}

.job-share-button-wrapper {
  margin-left: auto;
}

.bot-config {
  cursor: pointer;
}
 
.more {
  width: 32px;
  padding-left: 7px;
  border-radius: 20px;
  align-self: center;
}
.creitlbl{	color: rgba(7,16,26,0.5); font-size: 20px;text-align: center;	font-weight: 500;	 }
.creitmoney{color: #01CD75;	 text-align: center; margin-bottom: 0px;   	font-size: 24px;	font-weight: 500; }
.add-more-credits{   text-align: center;	color: rgba(7,16,26,0.7);	font-family: Roboto;	font-size: 14px;	font-weight: 500;	
.ant-input{ width: 100px; margin-left: 10px;}
.ant-input-number-input{width: 200px;}
}
.plan-name{
  width: 100%;
}
.add-plan-model{ 	color: rgba(7,16,26,0.7);	font-family: Roboto;	font-size: 14px;	font-weight: 500;	}
.plan-coloumn{ width: 45%; margin-right:4%;
.ant-input{width: 212px;}
}
.credit-btn{ margin-right: 10px; margin-left: 10px;}

.download-tracking-info-btn{
  margin-right: 10px;
}