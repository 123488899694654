.ant-row > .ant-form-item-control-wrapper,
.ant-row > .ant-form-item-control-wrapper > .ant-form-item-control,
.ant-row
  > .ant-form-item-control-wrapper
  > .ant-form-item-control
  > .ant-form-item-children {
  width: 100%;
}

.createdivshadow {
  box-shadow: 0 0 4px 0 rgba(7, 16, 26, 0.2);
  border-radius: 4px;
  padding: 15px 35% 15px 34px;
  display: inline-block;
  width: 96%;
  margin-top: 10px;

  .feature-save-btn {
    align-content: flex-end;
  }

  .feature-label {
    color: rgba(7, 16, 26, 0.9);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .ant-form-item {
    margin-bottom: 20px;
  }
  .ant-select,
  .ant-calendar-picker {
    width: 100%;
  }
}

.miles-slider-row {
  padding-top: 20px;
}

.ant-form-item-label label {
  color: #374251;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
#Name,
#Name:focus {
  min-width: 278px;
  max-width: 98%;
  color: #374251;
  font-family: Roboto;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 40px;
  border-style: none none dashed none !important; // to remove and default focus
  overflow: auto;
  border-radius: 0px;
}
.close-icon {
  position: relative;
  float: right;
  width: 20px;
  height: 20px;
  margin-right: 0;
  text-align: center;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.25);
  font-weight: 0px;
}
.orgcreatesection {
  .ant-select-selection {
    border-radius: 25px;
    width: 100px;
  }
  .ant-btn-primary {
    border-radius: 25px;
  }
}
.contrylist {
  width: 100%;
  .ant-select-selection {
    width: 100%;
  }
}
.ant-form-item-label {
  line-height: 28px;
}

.orgeneralinfo {
  border-bottom: 1px solid #e6e7e8;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 40px;
  display: inline-block;
  .ant-select {
    margin-bottom: 10px;
  }
}

.orginfohead {
  color: rgba(7, 16, 26, 0.9);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.Configure-Job-Status {
  display: flex;
  justify-content: space-between;
}

.Configure-Job-Status-title {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.Configure-Job-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.feature {
  display: flex;
}
.candidate-matching-job-feature-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 791px;
}

.input-matching-job-feature {
  width: 109px;
  margin: 16px 218px 0px 2px;
}
