.call-settings,
.contact-settings,
.email-settings,
.sharing-settings,
.consent-settings,
.auto-logout {
  width: 875px;
  padding-bottom: 20px;
  padding-left: 26px;
  padding-right: 28px;
  border-bottom: 1px solid #e6e7e8;
}
.contact-settings,
.email-settings,
.sharing-settings,
.auto-logout,
.call-settings,
.consent-settings {
  padding-top: 40px;
}

.call-configuration-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

.add-number {
  display: flex;
  align-items: flex-start;
}

.country-select,
.phone-number-input {
  margin-right: 16px;
}

.country-select {
  width: 80px;
  height: 32px;
}

.call-settings-header {
  margin-bottom: 32px;
}

.phone-number-input {
  width: 250px;
  height: 32px;
}

.contact-settings-header {
  margin-bottom: 20px;
}

.contact-settings-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

.contact-settings-description {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 16px;
  line-height: 16px;
}

.contact-type-options {
  margin-bottom: 25px;
}

.contact-options-heading {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
}
.consent-body {
  color: rgba(7, 16, 26, 0.7);
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  flex-direction: column;
}
.contact-settings-container {
  display: flex;
  flex-direction: column;
}
.contact-settings-admin {
  display: flex;
  justify-content: space-between;
}

.consent-body-feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 400px;
}

.email-configuration-header {
  margin-bottom: 32px;
}

.email-configuration-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 8px;
}

.smtp-server-title,
.smtp-port-title,
.pop3-server-title,
.pop3-port-title,
.username-title,
.password-title {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.smtp-server-input,
.smtp-port-input,
.pop3-server-input,
.pop3-port-input,
.username-input,
.password-input {
  width: 380px;
}

.email-settings-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0px;
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.email-settings-admin {
  display: flex;
  justify-content: space-between;
}
.consent-header {
  margin-bottom: 12px;
}

.sharing-title,
.auto-logout-title,
.consent-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 12px;
}

.sharing-description-text,
.auto-logout-description-text,
.consent-description-text {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
}

.consent-body {
  display: flex;
  color: rgba(7, 16, 26, 0.7);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  .connect-switch {
    margin: 0px 32px 0px 10px;
  }
}

.enable-sharing-title,
.enable-auto-logout-title {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.enable-auto-logout {
  margin-bottom: 26px;
}

.auto-logout-time {
  height: 24px;
  width: 760px;
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
}

.app-save,
.app-save:hover,
.app-save:focus,
.app-save:active {
  background-color: var(--arya-accent-color);
  color: white;
  border-radius: 15px;
}
