.addPortalPane{
    border: 1px solid #DADBDD;
    border-radius: 10px;
    margin: 51px 34px 50px 0;
    width: 80%;
    display: flex;
    flex-direction: row;
  }
  
  .portalDropdown{
    width: 20%;
    padding: 10px;
  }
  
  .portalAddBtn{
    width: 5%;
    padding: 10px;
  }