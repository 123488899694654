.share-button {
  border-radius: 30px;
}

.client-column-name {
  color: rgba(6, 15, 27, 0.9);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
}
