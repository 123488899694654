.CareerBuilder {
  position: relative;
}
.CareerBuilderTag {
  background-color: lightgrey;
  color: black;
  position: absolute;
  border-radius: 1em;
  width: 3.7em;
  height: 1.2em;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.2em;
  top: 1.8em;
  margin-bottom: 2px;
}
