.portals-container {
  display: flex;
  flex-direction: column;
}
.portals-container-wrapper {
  margin: 0px 0px 50px 34px;
}
.sourcelimit-section {
  width: 875px;
  border-bottom: 1px solid #e6e7e8;
  padding: 25px 28px 10px 0px;
}

.navigation-item-title-save {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.sourcing-behaviour-save-button {
  float: right;
}

.navigation-item-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}

.navigation-item-subtitle {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
}

.form-item-label {
  color: rgba(7, 16, 26, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.navigation-item-form-wrapper {
  padding: 20px 0px;
}
.portals-settings-row {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.portals-settings-input {
  width: 50%;
  margin-bottom: 10px;
}

.portals-settings-section {
  margin-bottom: 20px;
}

.portals-navigator {
  margin: 34px 0px 0 15px;
  width: 200px;
  display: flex;
  justify-content: flex-end;
}

.portals {
  margin: 51px 34px 50px 0;
  width: 875px;
}
.portals-jobboardlogo-banner {
  display: flex;
  width: 100%;

  .jobboard-image {
    cursor: pointer;
    width: 80px;
    min-height: 30px;

    img {
      max-width: 100%;
      max-height: 32px;
    }
  }

  .jobboard-switch {
    padding-left: 16px;
    .org-switch {
      margin: 0px 25px 10px 10px;
    }
  }
}
.jobboard-integration {
  border: 1px solid #e6e7e8;
  border-radius: 10px;
  margin-bottom: 16px;
  margin-top: 25px;
}
.jobboard-headerbanner {
  border-bottom: 1px solid #e6e7e8;
  padding: 25px 15px 20px;
  display: flex;
  justify-content: space-between;

  .job-boards-save {
    margin-top: 4px;
  }
}
.jobboard-bottombanner {
  background-color: #e7f8f0;
  text-align: center;
  padding: 10px;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;

  .show-more-icon {
    margin-top: 3px;
    float: right
  }
}
.portal-account-select {
  width: 75px;
}
.port-account-label {
  border: 1px solid #d3adf7;
  border-radius: 11px;
  background-color: #f9f0ff;
  color: #722ed1;
  font-size: 12px;
  padding: 3px 5px;
  margin: 2px 5px;
}
.disconnect-button,
.disconnect-button:focus,
.disconnect-button:hover {
  font-size: 14px;
  color: #f5222d;
  border: 1px solid #dadbdd;
  background-color: rgba(7, 16, 26, 0.05);
  margin-right: 10px;
  border-radius: 15px;
}
.port-account-more {
  border: 1px solid #dadbdd;
  background-color: #ffffff;
  color: rgba(7, 16, 26, 0.7);
  font-size: 14px;
  line-height: 22px;
  border-radius: 25px;
}
.portals-account-row {
  padding: 10px 0px 10px 10px;
  border-top: 1px solid rgba(7, 16, 26, 0.05);
}
.portals-adduserbtn-row {
  padding: 5px 5px 15px 225px;
}
.careerbuilder-username {
  width: 225px;
  margin-right: 3px;
}
.portal-sourcelimit {
  width: 80px;
  margin-right: 3px;
}
.portal-sourcelbl {
  margin: 0px;
  line-height: 38px;
  .ant-input {
    margin-right: 15px;
  }
  .ant-form-item-label {
    color: rgba(7, 16, 26, 0.7);
  }
}
.careerbuilder-password {
  width: 225px;
  margin-right: 2px;
}
.indeed-username,
.monster-username {
  width: 475px;
  margin-right: 3px;
}

.share-with-user {
  padding: 10px;
  border-bottom: thin solid lightgrey;
  display: flex;
  align-items: center;
}

.portal-sub-tab-selection{
  margin-top: 10px;
  margin-bottom: 10px;
}