
.portalCardView{
    border: 1px solid #DADBDD;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 80%;
}

.portalCardHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.portalHeaderLeft{
    display: flex;
    flex-direction: row;
}

.portalHeaderRight{
    display: flex;
    flex-direction: row;
}

.togglePortal{
    padding: 10px;
    align-self: center;
}

.portalLogo{
    align-self: center;
}

.portalLogoBlock{
    align-self: center;
    display: flex;
}

.portalLogoBlockItem{
    padding: 10px;
    align-self: center;
}

.portalSourceLimit{
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-self: center;
}

.portalConfigSave{
    padding: 10px;
    align-self: center;
}

.sourceLimitLabel{
    padding: 10px;
    align-self: center;
}

.sourceLimitValue{
    padding: 10px;
}
