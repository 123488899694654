.client-list {
  padding-top: 40px;
}

.client-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  .clients-count {
    margin-left: 24px;
    margin-right: 0px;
  }

  .active-clients-count {
    margin-left: 16px;
    color: var(--arya-accent-color);
    font-size: 12px;
  }
}

.client-list-title {
  color: rgba(7, 16, 26, 0.9);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.client-form-body {
  padding-bottom: 60px;
}
.client-list-column-headers {
  display: flex;
  padding: 12px 20px;
  background-color: #f4f6f8;

  .client-column-header,
  .contact-column-header,
  .primary-contact-column-header,
  .source-count-column-header,
  .status-column-header,
  .updated-column-header {
    color: rgba(6, 16, 27, 0.5);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 16px;
  }

  .client-column-header {
    flex: 1.5 1.5 0;
  }

  .contact-column-header {
    flex: 1.5 1.5 0;
  }

  .updated-column-header {
    flex: 1 1 0;
    text-align: center;
  }

  .source-count-column-header {
    flex: 1 1 0;
    text-align: center;
  }

  .status-column-header {
    flex: 1 1 0;
    text-align: center;
  }
}
.client-name-form {
  display: flex;
  .client-name-input,
  .client-name-input:focus {
    width: 250px;
    margin-right: 20px;
    border: none;
    border-radius: 0px;
    border-bottom: 1px dashed #a8b7c7;
  }

  .client-status-label {
    color: rgba(6, 16, 27, 0.7);
    font-size: 14px;
    line-height: 22px;
    margin-right: 8px;
  }
}
.client-list-row {
  display: flex;
  padding: 20px 20px;
  border-bottom: 1px solid #e6eaee;

  .client-column-item {
    flex: 1.5 1.5 0;
    cursor: pointer;
  }

  .contact-column-item {
    flex: 1.5 1.5 0;
  }

  .updated-column-item {
    flex: 1 1 0;
    text-align: center;
  }

  .source-count-column-item {
    flex: 1 1 0;
    text-align: center;
  }

  .status-column-item {
    flex: 1 1 0;
    text-align: center;
  }

  .client-column-item {
    .client-name {
      color: rgba(6, 15, 27, 0.9);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .client-id {
      color: rgba(5, 15, 29, 0.7);
      font-size: 12px;
      line-height: 16px;
    }
  }

  .contact-column-item {
    .client-email {
      color: rgba(6, 16, 27, 0.7);
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 7px;
    }

    .contact-number {
      color: rgba(6, 16, 27, 0.7);
      font-size: 12px;
      line-height: 16px;
    }
  }

  .primary-contact-column-item {
    .client-email {
      color: rgba(6, 15, 28, 0.7);
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 7px;
    }

    .contact-number {
      color: rgba(6, 16, 27, 0.7);
      font-size: 12px;
      line-height: 16px;
    }
  }

  .updated-column-item {
    .updated-time {
      color: rgba(4, 14, 30, 0.7);
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  .status-column-item {
    .status-text {
      margin-right: 10px;
    }
  }
}

.client-form-drawer {
  .ant-drawer-content-wrapper {
    width: 650px !important;
  }
}
.user-client-list-controls {
  display: flex;
  align-content: space-around;
  line-height: 40px;
}

.user-client-search {
  height: 40px;
  width: 240px;
  background-color: #ffffff;
  margin-right: 16px;
}

.user-client-search > .ant-input {
  border-radius: 20px;
}

.filter-users-clients {
  img {
    height: 18px;
  }

  line-height: 40px;
  border: none;
}

.add-user-icon {
  font-size: 40px;
  color: var(--arya-accent-color);
  cursor: pointer;
}
.ant-drawer {
  z-index: 2001;
}
.ant-form-item-label {
  line-height: 24px;
  color: rgba(7, 16, 26, 0.9);
  font-size: 14px;
  font-weight: 500;
}

.ats-id-input,
.source-count-input {
  width: 170px;
}

.location-input,
.emails,
.contact-numbers,
.clientid {
  display: flex;
}

.select-city-state-input,
.select-zipcode-input,
.company-email,
.source-count-item {
  margin-right: 10px;
}

.select-city-state-input,
.select-zipcode-input,
.select-country,
.primary-email,
.company-email,
.phone-number-input,
.mobile-number-input,
.company-address,
.source-count-item {
  width: 260px;
}

.company-url {
  width: 350px;
}
.client-form-footer {
  width: 650px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 20px 20px 0px 20px;
  background-color: white;
  box-shadow: 2px 2px 8px 2px rgba(32, 38, 50, 0.3);
  .ant-form-item {
    margin-bottom: 10px;
  }
  .buttons {
    float: right;

    button {
      border-radius: 25px;
    }
    .client-status-label {
      color: rgba(6, 16, 27, 0.7);
      font-size: 14px;
      line-height: 22px;
      margin-right: 8px;
    }
    .cancel,
    .cancel:hover {
      margin-right: 12px;
      border: 1px solid #dadbdd;
      color: rgba(7, 16, 26, 0.7);
    }

    .create-client,
    .create-client:hover {
      background-color: var(--arya-accent-color);
      color: white;
    }
  }
}

.drawer-select-dropdown {
  z-index: 3000;
}
.country-select {
  width: 80px !important;
  height: 32px;
}
.phone-number-title {
  color: #374251;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.security-role {
  color: #374251;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 10px;
}
.phone-number-list {
  width: 761px;
}
.add-number {
  display: flex;
  align-items: flex-start;
}

.country-select,
.phone-number-input {
  margin-right: 16px;
}

.country-select {
  width: 80px;
  height: 32px;
}
.submit-number,
.submit-number:hover,
.submit-number:focus {
  width: 135px;
  font-weight: 400;
  background-color: #fff;
  border: 1px dashed #dadbdd;
  color: rgba(7, 16, 26, 0.7);
  border-radius: 25px;
}
.connectradio {
  margin-bottom: 20px;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
